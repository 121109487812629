import React, { useEffect } from 'react';
import PublicTransactionsAppRouter from './router/PublicTransactionsAppRouter';

import { GlobalStyle } from '@prima-afp/prima-ui-elements/dist/layouts';
//onesignal
//import OneSignal from 'react-onesignal';

const PublicTransactionsApp = () => {
  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "05e7eab5-6ccc-4be8-8f1c-ff41bd7363f2"
  //   });
  // }, []);
  return (
    <GlobalStyle>
      <PublicTransactionsAppRouter />
    </GlobalStyle>
  );
};
export default PublicTransactionsApp;
