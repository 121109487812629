/* eslint-disable indent */
import { prop } from 'ramda';
import { ServiceFetcherCreatePublic } from '@prima-afp/common-libs/dist/libs/http';

const TOKEN_REQUEST_OPTIONS = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  body: new URLSearchParams({
    'client_id': process.env.REACT_APP_CLIENT_ID,
    'client_secret': process.env.REACT_APP_CLIENT_SECRET,
    'scope': process.env.REACT_APP_SCOPE,
  })
};

const STORAGE_ACCESS_TOKEN_NAME = 'accessToken';

const DTServiceFetcher = ServiceFetcherCreatePublic({
  baseUrl: process.env.REACT_APP_API_URL_DMENTES_WEB,
  tokenUrl: process.env.REACT_APP_API_URL_GENERATION,
  tokenRequestOptions: TOKEN_REQUEST_OPTIONS,
  accessTokenGetter: () => localStorage.getItem(STORAGE_ACCESS_TOKEN_NAME),
  onError: (errorMessage) => {
    console.error(`[ServiceFetcher][onError]: ${errorMessage}`);
  },
  onGetToken: (response) => {
    localStorage.setItem(STORAGE_ACCESS_TOKEN_NAME, prop('access_token', response));
  }
});

export default  {
    canal: 'traspasos',
    pathRedirectGoBackToInitBiometry: '/pasos2',
    pathBase: '/pasos3',
    pathRedirectSuccessValidation: '/pasos4',
    ServiceFetcher:DTServiceFetcher,
    needExecuteBeforeRedirectUrlAttemptsExceededModal: false,    
    btnTextStarValidation: 'Iniciar validación',
    components: {
      AttemptsExceededModal: {
        description: 'Superaste el máximo de intentos, podrás intentar dentro de 10 minutos.',
        buttonLabel: 'Entendido',
        buttonPathRedirect: '/pasos0',
      }
    },
    apiHooks: {
      useGetAttempts: {
        //baseUrl: process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL_TOC,
        //endpoint: '/validacion-facial/v1/detalle-identificacion/cantidad?documentNumber=',
        baseUrl: process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL_TOC_VALIDACION,
        endpoint: '/v1/validacion-facial/detalle-identificacion/intentos?documentNumber=',
        options: {
          method: 'GET',
        }
      },
      useCreateIdProcess: {
        //baseUrl: process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL_TOC,
        //endpoint: '/validacion-facial/v1/id-proceso',
        baseUrl: process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL_TOC_VALIDACION,
        endpoint: '/v1/validacion-facial/id-proceso',        
        options: {
          method: 'PUT', 
          body: ({ documentNumber }) => {
            return {
              urlWebSdkUiOrigin: process.env.REACT_APP_URL_WEB_SDKUI_ORIGIN,
              documentNumber,
            };
          },
        }
      },
      useGetResult: {
        //baseUrl: process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL_TOC,
        //endpoint: '/validacion-facial/v1/respuesta-facial/callback',
        baseUrl: process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL_TOC_AFILIACION,
        endpoint: '/v1/traspaso/registra-solicitud',
        options:{
          method: 'POST',
        }
      },
    },
    bodiesFromDatalayer:{
      //empezar
      BtnClickTakePhotoBiometryTOC:{
        body:{
          id: "MOD-MAIN-CLICK-TAKE-PHOTO-BIOMETRY-TOC",
          section: 'paso 3',
          option: 'tomar foto',
          procedure_type: 'traspasos digitales',
          location: '/pasos3/validacion-biometrica/inicio-de-proceso',  
        }
      },
      //exitoso
      ShowMessageResultBiometryToc:{
        body:{        
            section: 'paso 3',
            procedure_type: 'traspasos digitales',
            location: '/pasos3/validacion-biometrica/validacion-via-sdk',
            message: 'Autenticacion Biometrica exitosa'    
        }
      },
      //modal no se pudo validar, con intentos
      ShowModalAttempsFailedValidationBiometryTOC:{
        body:{
          section: 'paso 3',
          procedure_type: 'traspasos digitales',
          location: '/pasos3/validacion-biometrica/validacion-via-sdk',
          message: `No hemos podido validar tu identidad`
        }
      },
      //btn no se pudo validar, con intentos
      BtnClickRetryValidationBiometryTOC:{
        body:{
          section: 'paso 3',
          procedure_type: 'traspasos digitales',
          location: '/pasos3/validacion-biometrica/validacion-via-sdk',
          message: `No hemos podido validar tu identidad`,
          option: 'reintentar',
        }
      },
      //excediste la cantidad
      BtnClickChatWithExecutiveValidationTOC:{
        body:{
          section: 'paso 3',
          procedure_type: 'traspasos digitales',
          location: '/pasos3/validacion-biometrica/validacion-via-sdk',
          message: 'Excediste los intentos para validar tu identidad'
        }
      },
      //regresar
      BtnClickActionGoBackRedirectToInitBiometry:{
        body:{
          section: 'paso 3',
          procedure_type: 'traspasos digitales',
          location: '/pasos3/validacion-biometrica/inicio-de-proceso',
          option: 'Atras'
        }
      }
    }    
}; 

