import { Button } from '@prima-afp/prima-ui-elements/dist/core';
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { sendToData } from '../../constants/sendToDataLayer';
import { updateUrl } from '../../constants/updateUrl';
import { ValidStepFundType } from '../../constants/validate';
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import { useCatchFetch } from '../../hooks/useCatchFetch';
import { SendSaveEmployer, SendSaveFundType, checkTocAttempts, SavePhotoReco } from '../../services';
import { ButtonsContainer, DivBackButton } from './styles';

//importación del boton para toc
import { TocBiometricRoutes } from "../../constants";
import * as MODULES_CONFIG from '../../../../router/config';
import {getUtmData} from '../../constants/sendToDataLayer';

export const SendButton = ({buttonRef}) => {

    const { userWeb, userWebTransfer, codeValidateDocument, heldPosition, urlFlow, lastStep } = useStateDigitalTransfersContext();
    const { SendFetch, TransparentSendFetch, SetLoading } = useCatchFetch();
    const dispatch = useDispatchDigitalTransfersContext();    
    const history = useHistory();  
    const [formDisabled, setFormDisabled] = useState(true);    
    const variantes = {5: 'tipo_a', 6: 'tipo_b', 7: 'tipo_c' };

    useEffect(() => {
        if (!userWeb.workerType || userWeb.codeFundType == null) { setFormDisabled(true);}
        else if (userWeb.codeFundType == null) { setFormDisabled(true);}
        else if (heldPosition == null) { setFormDisabled(true);}
        else if (heldPosition && (userWeb.pepInstitutionName == '' || userWeb.pepPositionHeld == '' )) {setFormDisabled(true);}   
        else{setFormDisabled(false);}
    }, [userWeb, heldPosition]);


    const BackStep = () =>{
        dispatch({
            type: 'BACK_FUND_TYPE_STEP',
        });
        dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 1 } });
        updateUrl(history, window.location.href, '/pasos0');
    };

    const startValidation = async () => {
        try {
          SetLoading(true);
          var body = {
            documentType: userWeb.documentType,
            documentNumber: userWeb.documentNumber,
          };
          const resSavePhoto = await SendFetch(SavePhotoReco(body));
          if (resSavePhoto.code == 1) {
              dispatch({
                type: "FIELD",
                payload: { field: "showDivBenefits", value: false },
              });
              const currentUrl = window.location.href;
              var payload = {
                section: "paso 3",
                dni: userWeb.documentNumber,
                option: "validar identidad",
              };
              payload.proceso = "automatico";
              try {
                if(userWeb.useToc == true){
                    const tocRes = await TransparentSendFetch(checkTocAttempts(userWeb.documentNumber));
                
                    const urlTocConfig= TocBiometricRoutes(MODULES_CONFIG.genericProcedureBiometricTOCConfig.pathBase).START_VALIDATION_PROCESS;
                    const utmParamsToToc = getUtmData({},window.location.href );
                    const data = {
                        state: {
                          documentNumber: userWeb.documentNumber,
                          utmParams: utmParamsToToc,
                          fromConsumerModule: true,
                        },
                    };
                    sessionStorage.setItem('location-state-store', JSON.stringify(data,));
    
                    history.push({
                        pathname: urlTocConfig,
                        state: {
                            documentNumber: userWeb.documentNumber,
                            fromConsumerModule: true,
                            utmParams: utmParamsToToc,
                        },
                    });
                }else{
                    dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 3.5 },});
                    sendToData(currentUrl, "button_click", "button_click", payload);
                    updateUrl(history, currentUrl, "/pasos3validacion-biometrica");
                }
    
              } catch (error) {
                dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 3.5 },});
                sendToData(currentUrl, "button_click", "button_click", payload);
                updateUrl(history, currentUrl, "/pasos3validacion-biometrica");
              }
    
          } 
        }catch (e) {
          console.log("error", e);
        }
    
      };

    const Send = async (type) => {


        SetLoading(true);
        const validation = ValidStepFundType(userWeb, heldPosition, false);
        if (validation == null) {      
            const bodySaveEmployeer = {
                documentType: userWeb.documentType,
                documentNumber: userWeb.documentNumber,
                workerType: 'I',
                rucEmployer: null,
                nameEmployer: null,
            };
            await SendFetch(SendSaveEmployer(bodySaveEmployeer)).then((resSaveEmployeer) => {
                if(resSaveEmployeer.code == 0){
                    dispatch({
                      type: "FIELD_SECOND",
                      payload: { field1: "userWeb", field2: "workerType", value: null },
                    });
                    dispatch({
                      type: 'SET_CONFIG_MODAL',
                      payload: {
                          idModal:  "btn-step2-error-employeer-send",
                          content: 'Seleccione su fondo de nuevo',
                      }
                    });
                }else{
                    const bodySave = {
                        documentType: userWeb.documentType,
                        documentNumber: userWeb.documentNumber,
                        fundType: userWeb.fundType,
                        promoterCode: userWeb.promoterCode,
                        heldPosition: heldPosition,
                        pepInstitutionName: userWeb.pepInstitutionName,
                        pepPositionHeld: userWeb.pepPositionHeld
                    };

                    SendFetch(SendSaveFundType(bodySave))
                    .then(async (res) => {
                        dispatch({
                            type: "FIELD_SECOND",
                            payload: { field1: "userWeb", field2: "testABOption", value: type },
                        });

                         //Seccción para enviar atm.event
                        const metadata_1_ans = userWeb.workerType == "D"?  "trabajo de forma dependiente para una empresa" : "trabajo de forma independiente";
                        const metadata_2_ans = heldPosition?  "si" : "no";
                        const metadata_3_ans = userWeb.fundType;
                        const currentUrl = window.location.href;
                        var payload = {
                          section: 'paso 2', 
                          metadata_1: "¿que tipo de trabajador eres? - " + metadata_1_ans,
                          metadata_2: "¿ha desempeñado un cargo publico en el peru o en el extranjero durante los ultimos 5 años? - " + metadata_2_ans,
                          metadata_3: "tipo de fondo - fondo " + metadata_3_ans,
                          option: 'siguiente', 
                          count_2: 1,
                          dni: userWeb.documentNumber,
                          telefono: userWeb.phoneNumber,
                          email: userWeb.email,
                          status: codeValidateDocument == 4? 'no_reniec': 'reniec_activo',
                          variante: variantes[type] || 'original',
                          edad: userWeb.age
                        };
                        sendToData(currentUrl, "step", "paso_2", payload, urlFlow);     
                        if (lastStep < 3) {  dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 3 } });}  

                        //sección para  generar userWeb en sessionStorage
                        const payloadContext = {
                            "age": userWeb.age,
                            "documentType": userWeb.documentType,
                            "documentNumber": userWeb.documentNumber,
                            "tcConfirmation": userWeb.tcConfirmation,
                            "adpConfirmation": userWeb.adpConfirmation,
                            "pepInstitutionName": userWeb.pepInstitutionName,
                            "pepPositionHeld": userWeb.pepPositionHeld,
                            "heldPosition": userWeb.heldPosition,
                            "firstName": userWeb.firstName,
                            "email": userWeb.email,
                            "phoneNumber": userWeb.phoneNumber,
                            "address": userWeb.address,
                            "workerType": userWeb.workerType,
                            "rucEmployer": userWeb.rucEmployer,
                            "tradeNameEmployer": userWeb.tradeNameEmployer,
                            "codeFundType": userWeb.codeFundType,
                            "fundType": userWeb.fundType,
                            "testABOption": type,
                        };
                        const secretKey = sessionStorage.getItem("timestampFirstQuery");
                        const objetoEncriptado = CryptoJS.AES.encrypt(JSON.stringify(payloadContext), secretKey).toString();              
                        sessionStorage.setItem("userWeb", objetoEncriptado);      
                                                 
                        dispatch({
                            type: 'COMPLETE_FUND_TYPE_STEP',
                            payload: userWebTransfer
                        });
                        startValidation();                                         

                    });
                }
            }).catch((error) => {
                console.log(error);
                dispatch({
                  type: 'SET_CONFIG_MODAL',
                  payload: {
                      idModal:  "btn-step2-error-employeer-send",
                      content: 'Seleccione su fondo de nuevo',
                  }
                });
            });              
        } else {
            dispatch({
                type: 'SET_CONFIG_MODAL',
                payload: {
                    idModal:  "btn-step2-error-fund-type-send", 
                    content: validation,
                }
            });
            SetLoading(false);
        }
    };

    const isDisabled = () => {
        if (!userWeb.workerType || userWeb.codeFundType == null) { return true;}
        if (userWeb.codeFundType == null) { return true; }
        if (heldPosition == null) { return true; }
        if (heldPosition && (userWeb.pepInstitutionName == '' || userWeb.pepPositionHeld == '' )) {return true;}
        return false;
    };


    return (
        <ButtonsContainer>        

            {
                (window.innerWidth < 451) && 
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Button id="btn-fund-type-send" onClick={ ()=>Send(4)} ref={buttonRef} 
                        disabled={isDisabled()}
                        className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                    >               
                        Continuar
                    </Button> 

                    <DivBackButton  className={"newUiBtn"} onClick={BackStep}> Regresar  </DivBackButton>
                </div>
            }
            {
                (window.innerWidth > 451) && <div id="wrapperButtonsStep2" style={{width: "100%", display: "flex"}}>

                <Button id="btn-fund-type-send-test" onClick={ ()=>Send(4)} ref={buttonRef} 
                    disabled={isDisabled()}
                    className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                >               
                    Continuar
                </Button> 

                </div>
            }      

            
         </ButtonsContainer>
    );
};
