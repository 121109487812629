import { Button } from '@prima-afp/prima-ui-elements/dist/core';
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import React from 'react';
import { ValidStepFundType } from '../../constants/validate';
import { ButtonsContainer } from './styles';
import { SendSaveFundType, SendSaveEmployer} from '../../services';
import { useCatchFetch } from '../../hooks/useCatchFetch';
import { useHistory } from 'react-router-dom';
import {sendToData} from '../../constants/sendToDataLayer';
import {updateUrl} from '../../constants/updateUrl';

export const SendButton = ({buttonRef}) => {

    const { userWeb, userWebTransfer, showTypesFound, heldPosition, urlFlow, haveAsesor, lastStep, stepCurrent } = useStateDigitalTransfersContext();
    const {
        workerType,fundType
    } = userWeb;
    const { SendFetch } = useCatchFetch();
    const dispatch = useDispatchDigitalTransfersContext();    
    const history = useHistory();  

    const getValue = (str, currentURL) => {
        //const currentURL = window.location.href;
        const startIndex = currentURL.indexOf(str);
        if (startIndex !== -1) {
          // Encontrar la posición de "&" después de "utm_source="
          const endIndex = currentURL.indexOf("&", startIndex);    
          // Extraer la subcadena entre "utm_source=" y "&"
          const strValue = endIndex !== -1 ? currentURL.substring(startIndex + str.length, endIndex) : currentURL.substring(startIndex + str.length);
          return strValue;
        } else {
          return "not set";
        }       
    };

    const Send =async () => {

        const validation = ValidStepFundType(userWeb, heldPosition);
        if (validation == null) {        
            const bodySaveWT = {
                documentType: userWeb.documentType,
                documentNumber: userWeb.documentNumber,
                workerType: userWeb.workerType,
                rucEmployer: null,
                nameEmployer: null,
              };
              await SendFetch(SendSaveEmployer(bodySaveWT)).then((res) => {
                if(res.code == 0){
                  dispatch({
                    type: "FIELD_SECOND",
                    payload: { field1: "userWeb", field2: "workerType", value: null },
                  });
                  dispatch({
                    type: 'SET_CONFIG_MODAL',
                    payload: {
                        idModal:  "btn-step2-error-employeer-send",
                        content: 'Seleccione su fondo de nuevo',
                    }
                  });
                }else{
                    const bodySave = {
                        documentType: userWeb.documentType,
                        documentNumber: userWeb.documentNumber,
                        fundType: userWeb.fundType,
                        promoterCode: userWeb.promoterCode,
                        heldPosition: heldPosition,
                        pepInstitutionName: userWeb.pepInstitutionName,
                        pepPositionHeld: userWeb.pepPositionHeld
                    };
                    SendFetch(SendSaveFundType(bodySave))
                    .then(res => {
                        dispatch({
                            type: 'COMPLETE_FUND_TYPE_STEP',
                            payload: userWebTransfer
                        });
                        
                        const metadata_1_ans = userWeb.workerType == "D"?  "trabajo de forma dependiente para una empresa" : "trabajo de forma independiente";
                        const metadata_2_ans = heldPosition?  "si" : "no";
                        const metadata_3_ans = userWeb.fundType;
                        const currentUrl = window.location.href;
                        var payload = {
                          section: 'paso 2', 
                          metadata_1: "¿que tipo de trabajador eres? - " + metadata_1_ans,
                          metadata_2: "¿ha desempeñado un cargo publico en el peru o en el extranjero durante los ultimos 5 años? - " + metadata_2_ans,
                          metadata_3: "tipo de fondo - fondo " + metadata_3_ans,
                          option: 'siguiente', 
                          count_2: 1,
                          dni: userWeb.documentNumber,
                          telefono: userWeb.phoneNumber,
                          email: userWeb.email
                        };
                        sendToData(currentUrl, "step", "paso_2", payload, urlFlow);     
                        if (lastStep < 3) {  dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 3 } });}   
                        dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 3} });
                        window.location.href.includes('asesor') ? updateUrl(history, urlFlow,'/asesor/pasos3') : updateUrl(history, urlFlow,'/pasos3');
                    });
                }
           
              }).catch((error) => {
                  console.log(error);
                  dispatch({
                    type: 'SET_CONFIG_MODAL',
                    payload: {
                        idModal:  "btn-step2-error-employeer-send",
                        content: 'Seleccione su fondo de nuevo',
                    }
                  });
              });


            
        } else {
            dispatch({
                type: 'SET_CONFIG_MODAL',
                payload: {
                    idModal:  "btn-step2-error-fund-type-send", 
                    content: validation,
                }
            });
        }
    };

    const isDisabled = () => {
        if (!userWeb.workerType || userWeb.codeFundType == null) { return true;}
        if (userWeb.codeFundType == null) { return true; }
        if (heldPosition == null) { return true; }
        if (heldPosition && (userWeb.pepInstitutionName == '' || userWeb.pepPositionHeld == '' )) {return true;}
        return false;
    };

    return (
        <ButtonsContainer>
        {
            <Button id="btn-fund-type-send" onClick={Send} ref={buttonRef} disabled={isDisabled()}>               
                    Siguiente
            </Button>           
        }
         </ButtonsContainer>
    );
};
