import React from "react";
import { TocBiometricValidationCommon } from '@prima-afp/prima-security/module';
import { DigitalTransfersModule } from "../modules";
import { ApplicationRouter } from '../components';
import * as MODULES_CONFIG from './config';
import { useStateDigitalTransfersContext } from '../modules/digital-transfers/context/DigitalTransfersContext';

const PublicTransactionsAppRouter = () => {

  const addGlobalConfig = (config = {}) => ({
    ...config,
    app: {
      useGlobalAppContext: useStateDigitalTransfersContext
    }
  });
  return (
    <ApplicationRouter
      publicModules={[
        TocBiometricValidationCommon(addGlobalConfig(MODULES_CONFIG.genericProcedureBiometricTOCConfig)),
        DigitalTransfersModule(),
      ]}
    />
  );
};

export default PublicTransactionsAppRouter;
